import { PageTextFields } from "."
import { useState, useEffect } from 'react'

export default function CoordsButtons({copy, reload, fields}: {copy: () => void, reload: () => void, fields: PageTextFields}) {

    const [status, setStatus] = useState({
        isCopied: false,
        isFetched: false
    })

    useEffect(() => {
        let timeout: NodeJS.Timeout
        if (status.isCopied) {
            timeout = setTimeout(() => setStatus(prev => ({...prev, isCopied: false})), 2000)
        }

        return () => clearTimeout(timeout)
    }, [status.isCopied])

    useEffect(() => {
        let timeout: NodeJS.Timeout
        if (status.isFetched) {
            timeout = setTimeout(() => setStatus(prev => ({...prev, isFetched: false})), 2000)
        }

        return () => clearTimeout(timeout)
    }, [status.isFetched])

    const handleCopy = () => {
        if (!status.isCopied) {
            setStatus(prev => ({...prev, isCopied: true}))
            copy()
        }
    }

    const handleReload = () => {
        if (!status.isFetched) {
            setStatus(prev => ({...prev, isFetched: true}))
            reload()
        }
    }

    return (
        <div className="mt-11 flex justify-between gap-x-6">
            <button className={`w-1/3 bg-white py-4 font-inter text-md lg:text-xl rounded-xl${status.isCopied ? ' bg-black text-white' : ''}${!status.isCopied ? ' hover:bg-coords' : ''}`} onClick={handleCopy}>{fields.copy}</button>
            <button className={`w-2/3 border border-white rounded-xl text-white font-inter text-md lg:text-xl${status.isFetched ? ' bg-black text-white border-black' : ''}${!status.isFetched ? ' hover:bg-white hover:text-black' : ''}`} onClick={handleReload}>{fields.get_coords}</button>
        </div>
    )
}