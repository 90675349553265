import { CoordsLine, CoordsState, PageTextFields } from '.'
import { useEffect, useContext } from 'react'
import axios from 'axios'
import { ParamsContext } from '../context'
import { fallbackType, strapiUrl } from '../constants'
import { useSearchParams } from 'react-router-dom'

export default function Coords({coords, fields}: {coords: CoordsState, fields: PageTextFields}) {
    const [searchParams] = useSearchParams();
    const type = searchParams.get("type") || fallbackType;
    const { signature, setSignature } = useContext(ParamsContext)

    useEffect(() => {
        const controller = new AbortController()
        makeRequest(controller.signal)

        return () => controller.abort()

    }, [coords.latitude, coords.longitude])

    const makeRequest = async (signal: AbortSignal) => {
        if (coords.latitude && coords.longitude && signature) {
            try {
                const {data} = await axios.post(`${strapiUrl}/api/unique-link/handle/${signature}?type=${type}`, 
                {
                    lat: coords.latitude,
                    long: coords.longitude
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    }
                })
                if (data.is_success) {
                    alert(fields.data_sent)
                    setSignature(null)

                }
            } catch (e: any) {
                console.warn(e.message)
                alert(fields.data_sent_error)
            }
        }
    }

    return (
        <div className="w-full rounded-2xl bg-coords p-12 lg:p-24">
            <CoordsLine name={fields.latitude} value={coords.latitude ? coords.latitude : fields.loading} />
            <CoordsLine name={fields.longitude} value={coords.longitude ? coords.longitude : fields.loading} />
            {/* <CoordsLine name="Страна" value={'Россия'} />
            <CoordsLine name="Город" value={'Москва'} /> */}
        </div>
    )
}