import { Logo, HeaderLine, Locale } from '.'

export default function Header({locales}: {locales: string[]}) {
    return (
    <div className="grow-0">
        <div className="h-[88px] lg:max-w-6xl flex justify-between items-center gap-10 lg:mx-auto px-5 lg:px-0">
            <Logo />
            <HeaderLine />
            <Locale locales={locales} />
        </div>
    </div>
    )
}