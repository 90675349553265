import { PageTextFields } from ".";

export default function Footer({fields}: {fields: PageTextFields}) {
    return (
    <div className="bg-black grow-0 mt-10 lg:mt-0">
        <div className="max-w-6xl mx-auto text-white h-[88px] w-full flex justify-center items-center">
            <p>{fields.copyright}</p>
        </div>
    </div>
    )
}