import { Coords, CoordsButtons, PageTextFields, GoogleMap, CoordsState } from '.'
import { useRef, useState, useEffect } from 'react'



export default function CoordsBox({fields}: {fields: PageTextFields}) {

    const ref = useRef<HTMLDivElement>(null)
    const [coords, setCoords] = useState<CoordsState>({
        latitude: null,
        longitude: null,
        city: null,
        country: null
    })

    useEffect(() => {
        getCoords()
    }, [])

    const copy = () => {
        if (ref.current) {
            navigator.clipboard.writeText(ref.current.innerText.split('\n\n').join('\n').split(':\n').join(': '))
        }
    }

    const getCoords = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(pos => {setCoords(prev => ({...prev, latitude: pos.coords.latitude, longitude: pos.coords.longitude}))},
            error => {if (error.code === error.PERMISSION_DENIED) alert(fields.geolocation_disabled)})
            
        } else {
            alert(fields.geolocation_not_supported)
        }
    }

    return (
        <div className='md:w-1/2' >
            <div ref={ref}>
                <Coords coords={coords} fields={fields} />
            </div>
            <CoordsButtons copy={copy} reload={getCoords} fields={fields} />
            {/* {(coords.latitude !== null && coords.longitude !== null) ? <GoogleMap latitude={coords.latitude} longitude={coords.longitude} /> : <></>} */}
        </div>
    )
}