import { useLocation } from 'react-router-dom'
import { PageContent, PageTextFields, Loader } from '.'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { Header, Footer } from '.'
import { strapiUrl } from '../constants'

export default function PageTemplate() {

    const location = useLocation()
    const [fields, setFields] = useState<PageTextFields>({
      copy: '',
      get_coords: '',
      latitude: '',
      longitude: '',
      instructions: [],
      loading: '',
      copyright: '',
      geolocation_disabled: '',
      geolocation_not_supported: '',
      data_sent: '',
      data_sent_error: ''
  })
  const [locales, setLocales] = useState<string[]>([])
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    const controller = new AbortController()
    if (!locales.length) {
        getLocales(controller.signal)
    }

    return () => {
        controller.abort()
    }
  }, [])
  
  useEffect(() => {
    const controller = new AbortController()
    if (locales.length && location.pathname !== '/' && location.pathname !== '/null') {
      (async () => {
        await getTranslation(controller.signal)
        setIsLoaded(true)
      })()
    }

    return () => {
        controller.abort()
    }
  }, [location.pathname, locales.length])
  
  const getLocales = async (signal: AbortSignal) => {
    try {
        const {data} = await axios.get(`${strapiUrl}/api/i18n/locales`, {signal})
        if (data) {
            setLocales(prev => ([...data.map((el: any) => el.code)]))
        }
    } catch (e: any) {
        console.error(e.message)
    }
  }
  
  const getTranslation = async (signal: AbortSignal) => {
    try {
        const {data} = await axios.get(`${strapiUrl}/api/homepage?locale=${lang()}&populate=instructions`, {signal})
        setFields(prev => ({...prev, ...data.data.attributes}))
        setIsLoaded(true)
    } catch (e: any) {
        console.error(e.message)
    }
  }
  
  const lang = () => {
    return location.pathname.slice(1)
  }

    return isLoaded ? (<div className="bg-background min-h-screen flex flex-col">
        <Header locales={locales} />
        <PageContent fields={fields} />
        <Footer fields={fields} />
        </div>) :
      <Loader />
}