import { Outlet, useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { useContext, useEffect } from 'react'
import { ParamsContext } from '../context'
import { fallbackType } from '../constants'

export default function GlobalOutlet() {
    const navigate = useNavigate()
    const location = useLocation()
    const [params] = useSearchParams()
    const type = params.get("type") || fallbackType;
    const {setSignature} = useContext(ParamsContext)

    useEffect(() => {
        const signature = handleGetParam()
        redirectToBrowserLocale(signature)
    }, [])

    const redirectToBrowserLocale = (signature: string | null) => {
        if (location.pathname === '/') {
            navigate(`/${lang()}${signature ? '?signature=' + signature + '&type=' + type : ''}`)
        }
    }

    const handleGetParam = () => {
        const result = params.get('signature')
        setSignature(result)
        return result
    }

    const lang = () => {
        const locale = navigator.language.slice(0, 2)
        return locale === 'ru' ? 'ru' : 'en'
    }

    return <Outlet />
}