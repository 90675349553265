import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
import { useState, useEffect, useContext } from 'react'
import { ParamsContext } from '../context'
import { fallbackType } from '../constants'

export default function Locale({locales}: {locales: string[]}) {

    const {lang} = useParams()
    const navigate = useNavigate()
    const [currLang, setCurrLang] = useState<string | null>(null)
    const [searchParams] = useSearchParams();
    const type = searchParams.get("type") || fallbackType;
    const { signature } = useContext(ParamsContext)

    useEffect(() => {
        if (lang && !locales.includes(lang)) {
            navigate('/not-found')
        }
        if (lang) {
            setCurrLang(lang)
        }
    }, [])

    useEffect(() => {
        if (currLang && lang !== currLang) {
            navigate(`/${currLang}${signature ? '?signature=' + signature + "&type=" + type : ''}`);
        }
    }, [currLang])

    return (
        <select className='rounded bg-locale p-1.5 font-inter cursor-pointer' value={currLang || ''} onChange={e => setCurrLang(e.target.value)}>
            {locales.map(locale => <option key={locale} value={locale} className="p-1.5 font-inter cursor-pointer">{locale.toUpperCase()}</option>)}
        </select>
    )
}