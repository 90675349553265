import './App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { GlobalOutlet, PageTemplate } from './components'
import { ParamsContext, contextValues } from './context'


function App() {

  return (
    <div className="App">
      <ParamsContext.Provider value={contextValues}>
        <Router>
          <Routes>
            <Route path="/" element={<GlobalOutlet />}>
              <Route path="not-found" element={<div>Not found</div>} />
              <Route path=":lang" element={<PageTemplate />} />
            </Route>
          </Routes>
        </Router>
      </ParamsContext.Provider>
    </div>
  );
}

export default App;
