import { CoordsBox, Instructions, PageTextFields } from '.'



export default function Cont({fields}: {fields: PageTextFields}) {

    return (
        <div className="grow pt-8 md:py-14">
                <div className="lg:max-w-6xl flex flex-col md:flex-row justify-between gap-8 lg:gap-24 lg:mx-auto px-5 lg:px-0">
                    <CoordsBox fields={fields} />
                    <Instructions instructions={fields.instructions} />
                </div>
        </div>
    )
}